// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-container {
  background-color: #5dacbd;
}

/* Media query for viewport width > 576px */
@media (min-width: 576px) {
  .navbar-container {
    padding-left: 40px;
    padding-right: 100px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/styles.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA,2CAA2C;AAC3C;EACE;IACE,kBAAkB;IAClB,oBAAoB;IACpB,iBAAiB;IACjB,oBAAoB;EACtB;AACF","sourcesContent":[".navbar-container {\n  background-color: #5dacbd;\n}\n\n/* Media query for viewport width > 576px */\n@media (min-width: 576px) {\n  .navbar-container {\n    padding-left: 40px;\n    padding-right: 100px;\n    padding-top: 30px;\n    padding-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
